<template>
  <NuxtLink
    :to="
      '/' + $route.params.clientId + '/' + $route.params.caseId + '/overblik'
    "
    class="flex items-center"
  >
    <Icon
      name="homeLogo"
      class="h-auto w-[224px] md:w-[272px]"
      aria-label="Home logo"
    />
  </NuxtLink>
</template>
