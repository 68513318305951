<script setup lang="ts">
import type { MenuItem } from '@/types/ui'

const menuItems: MenuItem[] = [
  {
    title: 'Overblik',
    label: 'Boligens',
    mobileTitle: 'Boligens overblik',
    to: '/overblik',
  },
  {
    title: 'Tilstandsrapport',
    label: 'Opsummering',
    mobileTitle: 'Opsummering af tilstandsrapport',
    to: '/tilstandsrapport',
  },
  {
    title: 'Elinstallationsrapport',
    label: 'Opsummering',
    mobileTitle: 'Opsummering af elinstallationsrapport',
    to: '/elinstallationsrapport',
  },
  {
    title: 'Potentiale',
    label: 'Boligens',
    mobileTitle: 'Boligens potentiale',
    to: '/potentiale',
  },
]

const isMobileMenuOpen = ref(false)
// Close mobile menu when navigating
useRouter().afterEach(() => {
  if (isMobileMenuOpen.value) isMobileMenuOpen.value = false
})
</script>

<template>
  <header
    class="grid-outer fixed top-0 z-40 h-[--menu-height] w-full bg-home-white/90 py-6 backdrop-blur-lg"
  >
    <div class="col-content flex justify-between">
      <Logo />
      <MenuBar :menu-items="menuItems" />

      <!-- Mobile menu button -->
      <button
        aria-label="Toggle menu"
        class="flex h-8 w-8 items-center justify-center lg:hidden"
        @click="isMobileMenuOpen = !isMobileMenuOpen"
      >
        <!-- Burger icon -->
        <Icon
          v-if="!isMobileMenuOpen"
          name="burger"
          class="h-5 w-5"
          aria-label="Mobil menu knap"
        />
        <!-- Close icon -->
        <Icon
          v-if="isMobileMenuOpen"
          name="close"
          class="h-9 w-9"
          aria-label="Luk menu knap"
        />
      </button>
    </div>
  </header>
  <Transition name="slide">
    <MobileMenu
      v-show="isMobileMenuOpen"
      :menu-items="menuItems"
      class="z-30"
    />
  </Transition>
</template>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}
</style>
