<script setup lang="ts">
import type { MenuItem } from '@/types/ui'

const props = defineProps<MenuItem>()

const url = computed(
  () =>
    '/' + useRoute().params.clientId + '/' + useRoute().params.caseId + props.to
)
</script>

<template>
  <li
    :class="
      cn(
        'mb-5 text-lg font-bold hover:text-home-primary',
        $attrs.class ?? '',
        $route.path === url ? 'text-home-primary' : 'text-home-black'
      )
    "
  >
    <NuxtLink :to="url" class="flex flex-row items-center justify-between">
      <span class="leading-tight">
        <!-- Title -->
        {{ mobileTitle ?? title }}
      </span>
      <!-- Icon -->
      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          stroke="currentColor"
          stroke-linecap="round"
          d="M3 12h18m-6 6 6-6-6-6"
        />
      </svg>
    </NuxtLink>
  </li>
</template>
