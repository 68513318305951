<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { ToastViewport, type ToastViewportProps } from 'radix-vue'
import { cn } from '@/utils'

const props = defineProps<
  ToastViewportProps & { class?: HTMLAttributes['class'] }
>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})
</script>

<template>
  <ToastViewport
    v-bind="delegatedProps"
    :class="
      cn(
        'fixed right-3 top-24 z-[100] flex max-h-screen flex-col-reverse sm:bottom-auto sm:flex-col md:max-w-[450px]',
        props.class
      )
    "
  />
</template>
