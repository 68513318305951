<script setup lang="ts">
import type { MenuItem } from '@/types/ui'

const props = defineProps<MenuItem>()

const url = computed(
  () =>
    '/' + useRoute().params.clientId + '/' + useRoute().params.caseId + props.to
)

const pathIncludesUrl = computed(() => {
  return useRoute().path.includes(url.value)
})
</script>

<template>
  <li
    :class="
      cn(
        'text-lg leading-tight hover:text-home-primary',
        $attrs.class ?? '',
        pathIncludesUrl && 'text-home-primary'
      )
    "
  >
    <NuxtLink :to="url" class="flex flex-col">
      <!-- Label -->
      <span class="text-[0.5em] uppercase">
        {{ label }}
      </span>

      <!-- Title -->
      <span :class="cn('relative', pathIncludesUrl ? 'invisible' : 'visible')">
        {{ title }}
        <!-- Add bold title with visibility hidden to prevent layout shift -->
        <span
          :class="
            cn(
              'absolute left-0 top-0 font-bold',
              pathIncludesUrl ? 'visible' : 'invisible'
            )
          "
          aria-hidden="true"
        >
          {{ title }}
        </span>
      </span>
    </NuxtLink>
  </li>
</template>
