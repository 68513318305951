<script setup lang="ts">
const { address } = storeToRefs(usePropertyStore())

const description = computed(() => {
  return `${address.value?.address1}, ${address.value?.city} ${address.value?.postalCode}`
})
useMetaTags(description.value)
</script>
<template>
  <Menu />
  <UiToaster />
  <main class="grid-outer relative">
    <slot />
  </main>
</template>
